import {
  Prisma,
  UserProfileToCourse,
  UserSubscribedStatus,
} from '@prisma/client';

export type UserProfileWithRollups = Prisma.UserProfileGetPayload<{
  include: {
    userDayRollup: true;
  };
}>;

export type UserProfileWithUsis = Prisma.UserProfileGetPayload<{
  include: {
    userStoryInstances: true;
  };
}>;

export type UserProfileToCourseDict = Record<string, UserProfileToCourse>;

export const pendingSubscriptionStatuses: UserSubscribedStatus[] = [
  'PENDING',
  'INCOMPLETE',
];

export const inactiveSubscriptionStatuses: UserSubscribedStatus[] = [
  'CANCELED',
  'PAST_DUE',
];

export const authSubscriptionStatuses: UserSubscribedStatus[] = [
  'TRIAL',
  'SUBSCRIBED',
  'LIFETIME',
  'DEMO',
];

export type UserProfileWithSubscriptions = Prisma.UserProfileGetPayload<{
  include: {
    userProfilePathSubscriptions: {
      include: {
        nextCC: {
          include: {
            story: true;
          };
        };
      };
    };
  };
}>;
