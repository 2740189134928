import { storageHelper } from '@crone/shared/config/storage';
import { setCookie } from 'cookies-next';
import { Platform } from 'react-native';

export const setAdminCookies = (isAdmin: boolean) => {
  const key = 'isAdmin';
  // setCookie(key, isAdmin?.toString(), { path: '/', sameSite: 'lax' });
  if (typeof window !== 'undefined' && Platform.OS === 'web') {
    setCookie(key, isAdmin?.toString(), { path: '/', sameSite: 'lax' });
  }
  storageHelper.set(key, isAdmin);
  return isAdmin;
};
