import { MMKV } from 'react-native-mmkv';

const storage = new MMKV({
  id: 'root',
});

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
function getString(key: string): string | null {
  try {
    return storage.getString(key) ?? null;
  } catch {
    // not sure why this would fail... even reading the RN docs I'm unclear
    return null;
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
function setString(key: string, value: string): boolean {
  try {
    storage.set(key, value);
    return true;
  } catch {
    return false;
  }
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
function getValue<T>(key: string): T | null {
  try {
    const value = storage.getString(key) as any;
    return (JSON.parse(value) as T) ?? null;
  } catch {
    return null;
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
function set<T = any>(key: string, value: T): boolean {
  try {
    storage.set(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

/**
 * Removes an item from storage.
 * @param key The key to remove.
 * @returns
 */
function remove(key: string): boolean {
  try {
    storage.delete(key);
    return true;
  } catch {
    return false;
  }
}
/**
 * Clear all keys from storage.
 * @returns
 */
function clearAll(): boolean {
  try {
    storage.clearAll();
    return true;
  } catch {
    return false;
  }
}

// export all of these as a single object
export const storageHelper = {
  getString,
  setString,
  getValue,
  set,
  remove,
  clearAll,
};
