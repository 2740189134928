'use client';

import { AppProps } from 'next/app';
import SEO from '../next-seo.config';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
// import config from '@crone/config/src/tamagui.config';
import * as Notifications from 'expo-notifications';

// import '@tamagui/core/reset.css';
// import '@tamagui/font-inter/css/400.css';
// import '@tamagui/font-inter/css/700.css';
import 'raf/polyfill';

import '@crone/shared/css/tailwind.css';

import { AuthProvider } from '@crone/shared/hooks/useAuth';
import { GoogleTagManager } from '@next/third-parties/google';

import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, useEffect, useState } from 'react';
import { CrossPlatformProvider } from '@crone/shared/provider';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
// import { Toaster } from 'react-hot-toast';
// import { useRootTheme } from '@tamagui/next-theme';
// import { TamaguiProvider } from 'tamagui';
// import Head from 'next/head';
import { storageHelper } from '@crone/shared/config/storage';
import { AudioProvider } from '@crone/shared/components/shared/AudioProvider';

if (process.env.NODE_ENV === 'production') {
  require('../public/tamagui.css');
}

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    throw new Error('NEXT_PUBLIC_POSTHOG_KEY is not defined');
  }

  if (storageHelper.getValue<boolean>('isCronie')) {
    console.log('PostHog tracking is disabled based on local settings.');
  } else {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: 'https://app.posthog.com',
      // ui_host: 'https://app.posthog.com',
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    });
  }
}

// function renderSnippet() {
//   const opts = {
//     apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
//     // note: the page option only covers SSR tracking.
//     // Page.js is used to track other events using `window.analytics.page()`
//     page: true,
//   };

//   if (process.env.NODE_ENV === 'development') {
//     return snippet.max(opts);
//   }

//   return snippet.min(opts);
// }

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const App: FC<AppProps> = ({
  Component,
  pageProps,
}: AppProps<{ dehydratedState: unknown }>) => {
  // we could implement global error handling
  // queryCache: new QueryCache({
  //   onError: (error: Error, query) => {
  //     // 🎉 only show error toasts if we already have data in the cache
  //     // which indicates a failed background update
  //     if (query.state.data !== undefined) {
  //       alert(error.message);
  //       // toast.error(`Something went wrong: ${error.message}`);
  //     }
  //   },
  // }),
  // https://tkdodo.eu/blog/react-query-error-handling

  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60,
          },
        },
      })
  );

  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <>
      <DefaultSeo {...SEO} />
      {/* <Head>
        <meta name="apple-itunes-app" content="app-id=6476424527" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta charSet="utf-8" />
      </Head> */}
      <CrossPlatformProvider>
        <PostHogProvider client={posthog}>
          {/* <Script
            id="segment-script"
            dangerouslySetInnerHTML={{ __html: renderSnippet() }}
          /> */}
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ReactQueryDevtools initialIsOpen={false} />
              <AudioProvider>
                <AuthProvider>
                  <Component {...pageProps} />
                </AuthProvider>
              </AudioProvider>
            </Hydrate>
          </QueryClientProvider>
          {/* <Toaster
            toastOptions={{
              success: {
                iconTheme: {
                  primary: '#0ea5e9',
                  secondary: 'white',
                },
              },
            }}
            containerClassName="ml-20"
          /> */}

          {isProduction && <GoogleTagManager gtmId="GTM-NW4RJJT5" />}
        </PostHogProvider>
      </CrossPlatformProvider>
    </>
  );
};

// function ThemeProvider({ children }: { children: React.ReactNode }) {
//   // const [theme] = useRootTheme();

//   return (
//     // <NextThemeProvider
//     //   onChangeTheme={(next) => {
//     //     setTheme(next as any);
//     //   }}
//     // >
//     // <TamaguiProvider config={config} disableInjectCSS defaultTheme="light">
//     <CrossPlatformProvider defaultTheme="light">{children}</CrossPlatformProvider>
//     // </TamaguiProvider>
//   );
// }

export default App;
