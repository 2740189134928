// import { auth } from '@crone/shared/config/firebase';
import { AxiosRequestConfig } from 'axios';
import axiosClient from '@crone/shared/config/axios';
import { handleServerErrors } from './handleServerErrors';

const get = async <T, K = any>(
  url: string,
  params?: K,
  headers?: any
): Promise<T> =>
  axiosClient
    .get(url, { params, headers })
    .then(({ data }) => data as T)
    .catch((error) => {
      const result = handleServerErrors(error);
      if (!result.handledError) {
        throw result.error;
      }
      return result.error;
    });

const post = async <T, K = any>(
  url: string,
  payload: K,
  config?: AxiosRequestConfig
): Promise<T> => {
  // Use this function before sending the payload

  return axiosClient
    .post<T>(url, payload, config)
    .then((res) => res?.data as T)
    .catch((error) => {
      const result = handleServerErrors(error);
      if (!result.handledError) {
        throw result.error;
      }
      return result.error;
    });
};

const del = async <T>(url: string, params?: any): Promise<T> =>
  axiosClient
    .delete<T>(url, { params })
    .then((res) => res?.data as T)
    .catch((error) => {
      const result = handleServerErrors(error);
      if (!result.handledError) {
        throw result.error;
      }
      return result.error;
    });

export { get, post, del };
