import { storageHelper } from '@crone/shared/config/storage';
import { setCookie } from 'cookies-next';
import { Platform } from 'react-native';

export const setCollaboratorCookies = (isCollaborator: boolean) => {
  const key = 'isCollaborator';
  // setCookie(key, isAdmin?.toString(), { path: '/', sameSite: 'lax' });
  if (typeof window !== 'undefined' && Platform.OS === 'web') {
    setCookie(key, isCollaborator?.toString(), { path: '/', sameSite: 'lax' });
  }
  storageHelper.set(key, isCollaborator);
  return isCollaborator;
};
