import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from 'expo-av';
import { useEffect } from 'react';

async function setAudioMode() {
  try {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      staysActiveInBackground: true,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
      interruptionModeIOS: InterruptionModeIOS.DoNotMix,
      playThroughEarpieceAndroid: false,
    });
    console.log('Audio mode set successfully.');
  } catch (e) {
    console.error('Failed to set audio mode', e);
  }
}

// Custom hook
export function useSetAudioMode() {
  useEffect(() => {
    setAudioMode();
  }, []);
}
