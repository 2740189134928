import { shorthands } from '@tamagui/shorthands';
// import { tokens } from '@tamagui/themes/v2';
import { createMedia } from '@tamagui/react-native-media-driver';

import { animations } from '@crone/ui/src/animations';
import { TailwindColorsType, typedTailwindColors } from './tailwindColors';

import { radius, size, space, zIndex } from '@tamagui/themes';
import { createTamagui, createTokens, createFont } from 'tamagui';

const tokens = createTokens({
  size,
  space,
  zIndex,
  color: typedTailwindColors as TailwindColorsType,
  radius,
});

// Create a font:

// To work with the tamagui UI kit styled components (which is optional)
// you'd want the keys used for `size`, `lineHeight`, `weight` and
// `letterSpacing` to be consistent. The `createFont` function
// will fill-in any missing values if `lineHeight`, `weight` or
// `letterSpacing` are subsets of `size`.

const serifFont = createFont({
  family: 'Fira Sans',
  size: {
    1: 12,
    2: 14,
    3: 15,
    4: 16,
    5: 18,
    6: 20,
    7: 24,
    8: 30,
  },
  lineHeight: {
    1: 16,
    2: 17,
    3: 19,
    4: 22,
    5: 25,
    6: 32,
    7: 34,
    8: 37,
  },
  weight: {
    thin: '400',
    normal: '400',
    bold: '700',
  },
  face: {
    400: { normal: 'FiraRegular', italic: 'FiraItalic' },
    700: { normal: 'FiraBold' }, // Ensure this matches your font file
  },
});

const bodyFont = createFont({
  family: 'Wotfard',
  size: {
    1: 12,
    2: 14,
    3: 15,
    4: 16,
    5: 18,
    6: 20,
    7: 24,
    8: 30,
  },
  lineHeight: {
    1: 16,
    2: 17,
    3: 19,
    4: 22,
    5: 25,
    6: 28,
    7: 30,
    8: 34,
  },
  weight: {
    thin: '400',
    normal: '400',
    bold: '700',
  },
  face: {
    400: { normal: 'Wotfard', italic: 'WotfardItalic' },
    700: { normal: 'WotfardBold' },
  },
});

export const config = createTamagui({
  animations,
  themes: {
    dark: {
      ...tokens.color,
      background: '#ffffff',
    },
    light: {
      ...tokens.color,
      background: '#ffffff',
    },
  },
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  onlyAllowShorthands: false,
  shorthands,
  fonts: {
    body: bodyFont,
    heading: bodyFont,
    serif: serifFont,
  },
  defaultFont: 'body',
  settings: {
    disableRootThemeClass: true,
  },
  tokens,
  media: createMedia({
    xs: { maxWidth: 640 },
    sm: { maxWidth: 768 },
    md: { maxWidth: 1024 },
    lg: { maxWidth: 1280 },
    xl: { maxWidth: 1536 },
    xxl: { maxWidth: 1600 },
    gtXs: { minWidth: 640 + 1 },
    gtSm: { minWidth: 768 + 1 },
    gtMd: { minWidth: 1024 + 1 },
    gtLg: { minWidth: 1280 + 1 },
    short: { maxHeight: 820 },
    tall: { minHeight: 820 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
});

// old ones from tamagui (replaced them above with tailwind defaults)

// media: createMedia({
//   xs: { maxWidth: 660 },
//   sm: { maxWidth: 800 },
//   md: { maxWidth: 1020 },
//   lg: { maxWidth: 1280 },
//   xl: { maxWidth: 1420 },
//   xxl: { maxWidth: 1600 },
//   gtXs: { minWidth: 660 + 1 },
//   gtSm: { minWidth: 800 + 1 },
//   gtMd: { minWidth: 1020 + 1 },
//   gtLg: { minWidth: 1280 + 1 },
//   short: { maxHeight: 820 },
//   tall: { minHeight: 820 },
//   hoverNone: { hover: 'none' },
//   pointerCoarse: { pointer: 'coarse' },
// }),

// for the compiler to find it
export default config;
