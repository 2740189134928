import { storageHelper } from '@crone/shared/config/storage';
import { setCookie } from 'cookies-next';
import { Platform } from 'react-native';

export const setLoggedInCookies = (isLoggedIn: boolean) => {
  const key = 'loggedIn';
  if (typeof window !== 'undefined' && Platform.OS === 'web') {
    setCookie(key, isLoggedIn?.toString(), { path: '/', sameSite: 'lax' });
  }
  storageHelper.set(key, isLoggedIn);
  return isLoggedIn;
};
