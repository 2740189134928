import { logoutHelper, redirectHelper } from './logoutHelper';
const UNAUTH_402_URL = '/pricing';

export const handleServerErrors = (e: any) => {
  // if client side
  console.log('e', e);
  if (e.response?.status) {
    if (e.response.status === 403) {
      logoutHelper();

      return { handledError: true, error: e };
    } else if (e.response.status === 402) {
      redirectHelper(UNAUTH_402_URL);
      return { handledError: true, error: e };
    } else if (e.response.status === 429) {
      alert('Too many requests, please try again later');
      return { handledError: true, error: e };
    }

    // throw e.response.data;
    return { handledError: false, error: e };
  } else {
    console.log('error', e);
    return { handledError: false, error: e };
    // throw e;
  }
};
