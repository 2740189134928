import {
  CustomToast,
  TamaguiProvider,
  TamaguiProviderProps,
  ToastProvider,
  config,
} from '@crone/ui';
import { PortalProvider } from '@tamagui/portal';
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
// import { useColorScheme } from 'react-native';

import { ToastViewport } from './ToastViewport';
import { SolitoImageProvider } from './SolitoImageProvider';
import { useSetAudioMode } from '../hooks/useSetAudioMode';

// this provider is shared between web and mobile
export function CrossPlatformProvider({
  children,
  ...rest
}: Omit<TamaguiProviderProps, 'config'>) {
  // const scheme = useColorScheme();
  useSetAudioMode();
  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <PortalProvider shouldAddRootHost>
        <TamaguiProvider config={config} {...rest}>
          <SolitoImageProvider>
            <ToastProvider
              swipeDirection="horizontal"
              duration={4000}
              native={['mobile']}
            >
              {children}

              <CustomToast />
              <ToastViewport />
            </ToastProvider>
          </SolitoImageProvider>
        </TamaguiProvider>
      </PortalProvider>
    </SafeAreaProvider>
  );
}
