/* Firestore is great for one-off document type media features
 * and marketing-style toys (like a user document) */

import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { GoogleAuthProvider, OAuthProvider, getAuth } from 'firebase/auth';

import { getStorage } from 'firebase/storage';

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication and set persistence
// const auth = initializeAuth(app, {
//   persistence: browserLocalPersistence,
// });
const auth = getAuth();

const storage = getStorage();

const STORAGE_BUCKET = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;

// eslint-disable-next-line import/no-unused-modules
export { auth, db, storage, googleProvider, appleProvider, STORAGE_BUCKET };

// console.log(app.name ? 'Firebase Mode Activated!' : 'Firebase Mode FAILED :(');

// const EMULATORS_STARTED = 'EMULATORS_STARTED';

// function startEmulators() {
//   if (!global[EMULATORS_STARTED]) {
//     global[EMULATORS_STARTED] = true;
//     db.settings({ host: 'localhost:8080', ssl: false, merge: true });
//     functions.useEmulator('localhost', 5001);
//     // if you end up with issues using the functions emulator and regions
//     // see: https://github.com/firebase/firebase-tools/issues/3537
//     // functions = firebase.app().functions('us-east4');
//     auth.useEmulator('http://localhost:9099');
//     storage.useEmulator('localhost', 9199);
//   }
// }

// Uncomment the following line to let functions use the functions emulator
// if (process.env.NODE_ENV === 'development') {
//   startEmulators();
// }
// these are not secret, they are public
// const firebaseConfig = {
//   apiKey: 'AIzaSyDsutN7cFJLraKQFFWI2D9FIvCmnGWKfmI',
//   authDomain: 'crone-local.firebaseapp.com',
//   databaseURL: 'https://crone-local-default-rtdb.firebaseio.com',
//   projectId: 'crone-local',
//   storageBucket: 'crone-local.appspot.com',
//   messagingSenderId: '838359577655',
//   appId: '1:838359577655:web:bd815ee56dbecb8715fbb2',
//   // Include any other Firebase config variables here
// };
