import { UserSubscribedStatus } from '@prisma/client';
import { storageHelper } from '@crone/shared/config/storage';

const key = 'subscribedStatus';

export const setSubscriptionCookies = (
  subscribedStatus: UserSubscribedStatus
) => {
  const value = subscribedStatus?.toString();

  storageHelper.set(key, value);
  // setCookie(key, value, { path: '/', sameSite: 'lax' });
};

export const getSubscriptionCookies = (): UserSubscribedStatus | null => {
  const subscribedCookie = storageHelper.getString(
    'subscribedStatus'
  ) as UserSubscribedStatus;

  return subscribedCookie;
};
