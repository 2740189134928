import { APP_URI } from '@crone/shared/config/constants';
import { auth } from '@crone/shared/config/firebase';
import { setSubscriptionCookies } from '@crone/shared/utils/authHelpers/subscriptionCookies';
import { setOnboardedCookies } from '@crone/shared/utils/authHelpers/onboardedCookies';
import { setLoggedInCookies } from '@crone/shared/utils/authHelpers/loggedInCookies';
import { setWaitlistedCookies } from '@crone/shared/utils/authHelpers/waitlistedCookies';

export const logoutHelper = async () => {
  if (typeof window !== 'undefined' && window.location) {
    await auth.signOut();
    setSubscriptionCookies('NONE');
    setOnboardedCookies(false);
    setLoggedInCookies(false);
    setWaitlistedCookies(false);
    window.location.href = `${APP_URI}/login`;
  }

  // SIMPLIFYING THIS FOR NOW
  // if (typeof window !== 'undefined' && window.location) {
  //   let escapedPath = '';
  //   if (
  //     window.location.pathname &&
  //     window.location.pathname?.trim()?.length > 1
  //   ) {
  //     escapedPath = encodeURIComponent(window.location.pathname);
  //   }
  //   // setCookie('loggedIn', 'false', { path: '/', sameSite: 'lax' });
  //   window.location.href = `${APP_URI}/login?from=${escapedPath}`;
  // } else if (typeof window !== 'undefined') {
  //   window.location.href = `${APP_URI}/login`;
  // }
};

export const redirectHelper = (path: string) => {
  if (typeof window !== 'undefined' && window.location) {
    // setCookie('loggedIn', 'false', { path: '/', sameSite: 'lax' });
    // use expo router navigation
    window.location.href = `${window.location.origin}${path}`;
  }
};
