import { createContext, useContext, useEffect, useState } from 'react';
import { UserProfile } from '@prisma/client';
import { auth } from '@crone/shared/config/firebase';
import * as fetcher from '@crone/shared/utils/fetcher';
import { setLoggedInCookies } from '@crone/shared/utils/authHelpers/loggedInCookies';
import { setAdminCookies } from '@crone/shared/utils/authHelpers/adminCookies';
import { setSubscriptionCookies } from '@crone/shared/utils/authHelpers/subscriptionCookies';
import { setWaitlistedCookies } from '@crone/shared/utils/authHelpers/waitlistedCookies';
import { useQuery } from '@tanstack/react-query';
import { User } from 'firebase/auth';
import { storageHelper } from '../config/storage';
import { authSubscriptionStatuses } from '../interfaces/userProfile';
import { setCollaboratorCookies } from '../utils/authHelpers/collaboratorCookies';

export type Role = 'admin' | 'collaborator' | 'user';

export interface AuthContextType {
  user: User | null;
  userProfile: UserProfile | null | undefined;
  isAuthenticated: boolean | null;
  isAdmin: boolean | null;
  isCollaborator: boolean | null;
  isSubscribed: boolean | null;
  showUnreleased: boolean | null;
  authLoading: boolean;
}

const initialAuthContextValue: AuthContextType = {
  user: null,
  userProfile: null,
  isAuthenticated: null,
  isAdmin: null,
  isCollaborator: null,
  isSubscribed: null,
  showUnreleased: null,
  authLoading: true,
};

export const AuthContext = createContext<AuthContextType>(
  initialAuthContextValue
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useAuthHelper();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Hook to use the auth context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  const isDev = process.env.NODE_ENV !== 'production';
  if (!context && isDev) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context as AuthContextType;
};

const useAuthHelper = (): AuthContextType => {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const [isCollaborator, setIsCollaborator] = useState<boolean | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [authLoading, setAuthLoading] = useState<boolean>(true);

  const { data: userProfile } = useQuery<UserProfile, Error>(
    ['userProfile', user?.uid],
    async () => {
      return await fetcher.get<UserProfile>('/api/userProfile/' + user?.uid);
    },
    {
      enabled: !!user?.uid,
    }
  );

  useEffect(() => {
    if (userProfile) {
      // const onboardedJson =
      //    userProfile?.onboardedJson as unknown as UserProfileOnboardedJson;
      // setOnboardedCookies(onboardedJson?.isFounder || false);

      const subStatus = userProfile?.subscribedStatus;
      setSubscriptionCookies(subStatus || 'NONE');

      const subscribed = authSubscriptionStatuses.includes(subStatus);
      setIsSubscribed(subscribed);

      setWaitlistedCookies(userProfile?.offWaitlist || false);
    }
  }, [userProfile, user]);

  useEffect(() => {
    const handleTokenAndUserState = async (user: User, retry = 2) => {
      try {
        // const subscribedCookie = getSubscriptionCookies();
        const {
          claims,
          token: firstToken,
          expirationTime,
        } = await user.getIdTokenResult();

        const role = (claims?.role as Role) || ('user' as Role);

        let token = firstToken;
        // Check token expiry and refresh if needed
        const tokenExpiry = new Date(expirationTime);
        const now = new Date();
        // we should buffer the token expiry by 20 minute
        if (tokenExpiry.getTime() - now.getTime() < 20 * 60 * 1000) {
          token = await user.getIdToken(true); // Force refresh
        }

        storageHelper.setString('token', token);
        // setTokenCookies(token);

        setUserFirebaseState(user, role);
      } catch (error) {
        // if it's a network error, we should try again
        if (error.code === 'auth/invalid-credential') {
          if (retry > 0) {
            // pause for 1 second before retrying
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await handleTokenAndUserState(user, retry - 1);
          } else {
            if (user) {
              auth.signOut();
            }
          }
        }
        console.error('Failed to handle token and user state:', error);
        setAuthLoading(false); // Set loading to false on error
      }
    };

    const setUserFirebaseState = (user: User | null, role: Role | false) => {
      setIsAuthenticated(!!user);
      setUser(user);

      // set admin cookies
      const isAdmin = role === 'admin';
      setAdminCookies(isAdmin);
      setIsAdmin(isAdmin);

      // set collaborator cookies
      const isCollaborator = role === 'collaborator';
      setCollaboratorCookies(isCollaborator);
      setIsCollaborator(isCollaborator);

      setLoggedInCookies(!!user);
      setAuthLoading(false);
    };

    const handleAuthStateChanged = async (user: User) => {
      setAuthLoading(true);
      // return;
      if (user) {
        try {
          // Attempt to refresh the token
          await handleTokenAndUserState(user);
        } catch (error) {
          console.error('Token refresh failed, logging out user.', error);
          // Log the user out
          auth.signOut();
          setUserFirebaseState(null, false);
        }
        // await fetchToken(user);
      } else {
        setUserFirebaseState(null, false);
      }
      setAuthLoading(false);
    };

    const unsubscribeAuthChange = auth.onAuthStateChanged(
      handleAuthStateChanged
    );

    const unsubscribeTokenChange = auth.onIdTokenChanged(
      handleAuthStateChanged
    );

    return () => {
      unsubscribeAuthChange();
      unsubscribeTokenChange();
    };
  }, []);

  return {
    user,
    userProfile,
    isAuthenticated,
    isAdmin,
    isCollaborator,
    isSubscribed: isSubscribed || isAdmin || isCollaborator,
    showUnreleased: isCollaborator || isAdmin,
    authLoading,
  };
};
