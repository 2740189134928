import { UserProfile } from '@prisma/client';
import { storageHelper } from '@crone/shared/config/storage';

export const setWaitlistedCookies = (
  waitlistBoolean: UserProfile['offWaitlist']
) => {
  if (waitlistBoolean) {
    return storageHelper.set('offWaitlist', waitlistBoolean);
  } else {
    return null;
  }
};

export const getWaitlistedCookies = (): boolean | null => {
  return storageHelper.getValue<boolean | null>('offWaitlist');
};
