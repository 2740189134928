import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Audio, AVPlaybackStatus } from 'expo-av';

// Constants controlling the fade-out effect
const FADE_OUT_STEPS = 10; // Number of steps in the fade-out transition
const FADE_OUT_INTERVAL = 200; // Interval in milliseconds between each fade-out step

// CUTOFF EARLIER: Increase the value (e.g., from - 880 to - 1000)
// CUTOFF LATER: Decrease the value (e.g., from - 880 to - 500)
const FADE_OUT_CUTOFF_OFFSET = 620; // Offset to start the fade-out earlier

// RESTART EARLIER: Increase the absolute value
// RESTART LATER: Decrease the absolute value
const FADE_OUT_RESTART_OFFSET = 500; // Offset to restart the fade-out

interface AudioControlContextType {
  loadSound: (params: {
    uri: string;
    initialRate: number;
    shouldPlay: boolean;
    startFrom?: number | undefined | null;
    endAt?: number | undefined | null;
  }) => void;
  play: () => void;
  pause: () => void;
  stop: () => void;
  unload: () => void;
  startOver: () => void;
  setRate: (rate: number) => void;
  setVolume: (volume: number) => void;
}

interface AudioStatusContextType {
  positionMillis: number;
  durationMillis?: number;
  isPlaying: boolean;
}

const AudioControlContext = createContext<AudioControlContextType | undefined>(
  undefined
);
const AudioStatusContext = createContext<AudioStatusContextType | undefined>(
  undefined
);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const soundRef = useRef<Audio.Sound | null>(null);
  const isLoadingRef = useRef(false); // Lock to prevent multiple simultaneous loads
  const endAtRef = useRef<number | undefined>(undefined); // Reference for the time to end playback (in seconds)
  const currentRate = useRef<number>(1); // Current playback rate
  const fadeOutStarted = useRef(false); // Flag to prevent multiple fade-out initiations
  const [status, setStatus] = useState<AudioStatusContextType>({
    isPlaying: false,
    positionMillis: 0,
  });

  // Function to load and initialize the sound
  const loadSound = useCallback(
    async ({
      uri,
      initialRate,
      shouldPlay,
      endAt,
      startFrom,
    }: {
      uri: string;
      initialRate: number;
      shouldPlay: boolean;
      endAt?: number;
      startFrom?: number;
    }) => {
      if (isLoadingRef.current) return; // Prevent re-entry if already loading
      isLoadingRef.current = true;
      endAtRef.current = endAt; // Store the end time (in seconds)
      fadeOutStarted.current = false; // Reset fade-out flag
      currentRate.current = initialRate; // Store the initial playback rate

      try {
        // Unload any existing sound to avoid conflicts
        if (soundRef.current) await soundRef.current.unloadAsync();

        const newSound = new Audio.Sound();

        // Load the sound file without starting playback
        await newSound.loadAsync(
          { uri },
          {
            shouldPlay: false,
            rate: initialRate,
            shouldCorrectPitch: true,
          }
        );

        soundRef.current = newSound;
        // Set up the playback status update handler
        newSound.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);

        if (startFrom) {
          // Calculate the starting position in milliseconds
          // The subtraction adjusts the start time slightly earlier
          const position =
            startFrom * 1000 - FADE_OUT_RESTART_OFFSET * initialRate;
          await newSound.setPositionAsync(position);
        }

        await setRate(initialRate); // Set the playback rate

        // Start playback if specified and endAt is not too close to the start
        if (shouldPlay && (endAt === undefined || endAt > 1.5)) {
          await newSound.playAsync();
        }
      } catch (error) {
        console.error('Error loading sound:', error);
      } finally {
        isLoadingRef.current = false; // Reset loading lock
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingRef, soundRef]
  );

  // Function to gradually reduce the volume and pause the sound
  const fadeOut = useCallback(async () => {
    try {
      if (!soundRef.current || fadeOutStarted.current) return; // Avoid re-entry
      fadeOutStarted.current = true; // Mark fade-out as started

      for (let i = 1; i <= FADE_OUT_STEPS; i++) {
        // Decrease volume incrementally
        await soundRef.current.setVolumeAsync(1 - i / FADE_OUT_STEPS);
        // Wait for the specified interval adjusted by the playback rate
        await new Promise((resolve) =>
          setTimeout(resolve, FADE_OUT_INTERVAL / currentRate.current)
        );
      }

      await soundRef.current.pauseAsync(); // Pause playback after fade-out
      await soundRef.current.setVolumeAsync(1); // Reset volume to full for next playback
    } catch (error) {
      console.error('Error fading out:', error);
    }
  }, []);

  // Handler that's called on every playback status update
  const onPlaybackStatusUpdate = useCallback(
    async (playbackStatus: AVPlaybackStatus) => {
      if (!playbackStatus.isLoaded) {
        // Sound is not loaded; reset status
        setStatus({
          isPlaying: false,
          positionMillis: 0,
          durationMillis: 0,
        });
        return;
      }

      // Update the playback status
      setStatus({
        isPlaying: playbackStatus.isPlaying,
        positionMillis: playbackStatus.positionMillis,
        durationMillis: playbackStatus.durationMillis,
      });

      // Calculate the end time in milliseconds
      const endAtMillis = (endAtRef.current || 0) * 1000; // Convert endAt to milliseconds

      // Check if we should start the fade-out effect
      // The fade-out starts when the current position reaches:
      // endAtMillis - (total fade-out duration) - additional offset

      if (
        endAtMillis !== 0 &&
        playbackStatus.positionMillis >=
          endAtMillis -
            FADE_OUT_INTERVAL * FADE_OUT_STEPS -
            FADE_OUT_CUTOFF_OFFSET &&
        playbackStatus.isPlaying &&
        !fadeOutStarted.current
      ) {
        fadeOut(); // Start the fade-out process
      }
    },
    // IMPORTANT: adding fadeOut causes a loop of calls to this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Function to start or resume playback
  const play = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current.playAsync();
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  }, []);

  // Function to pause playback
  const pause = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current.pauseAsync();
    } catch (error) {
      console.error('Error pausing sound:', error);
    }
  }, []);

  // Function to restart playback from the beginning
  const startOver = useCallback(async () => {
    try {
      if (!soundRef.current) return;
      await soundRef.current.setPositionAsync(0); // Reset position to start
      await soundRef.current.playAsync(); // Start playback
    } catch (error) {
      console.error('Error starting over:', error);
    }
  }, []);

  // Function to unload the sound and reset status
  const unload = useCallback(async () => {
    try {
      if (soundRef.current) {
        await soundRef.current.unloadAsync(); // Unload the sound
        soundRef.current = null;
      }

      // Reset playback status
      setStatus({
        isPlaying: false,
        positionMillis: 0,
        durationMillis: 0,
      });
    } catch (error) {
      console.error('Error unloading sound:', error);
    }
  }, []);

  // Function to stop playback
  const stop = useCallback(async () => {
    try {
      if (soundRef.current) await soundRef.current.stopAsync();
    } catch (error) {
      console.error('Error stopping sound:', error);
    }
  }, []);

  // Function to set the playback rate
  const setRate = useCallback(async (rate: number) => {
    try {
      if (soundRef.current) {
        await soundRef.current.setRateAsync(rate, true); // Update rate immediately
        currentRate.current = rate; // Store the current rate
      }
    } catch (error) {
      console.error('Error setting rate:', error);
    }
  }, []);

  // Function to set the playback volume
  const setVolume = useCallback(async (volume: number) => {
    try {
      if (soundRef.current) await soundRef.current.setVolumeAsync(volume);
    } catch (error) {
      console.error('Error setting volume:', error);
    }
  }, []);

  // Memoized control functions to prevent unnecessary re-renders
  const audioControlValue = useMemo(
    () => ({
      loadSound,
      play,
      pause,
      stop,
      unload,
      setRate,
      startOver,
      setVolume,
    }),
    [loadSound, play, pause, stop, unload, setRate, startOver, setVolume]
  );

  // Memoized status value
  const audioStatusValue = useMemo(() => status, [status]);

  return (
    <AudioControlContext.Provider value={audioControlValue}>
      <AudioStatusContext.Provider value={audioStatusValue}>
        {children}
      </AudioStatusContext.Provider>
    </AudioControlContext.Provider>
  );
};

// Hook to access audio control functions
export const useAudioControl = () => {
  const context = useContext(AudioControlContext);
  if (!context) {
    throw new Error('useAudioControl must be used within an AudioProvider');
  }
  return context;
};

// Hook to access audio status
export const useAudioStatus = () => {
  const context = useContext(AudioStatusContext);
  if (!context) {
    throw new Error('useAudioStatus must be used within an AudioProvider');
  }
  return context;
};
