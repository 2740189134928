import axios, { AxiosRequestConfig } from 'axios';
import { logoutHelper } from '@crone/shared/utils/logoutHelper';
import { auth } from './firebase';
import { storageHelper } from './storage';
import { Platform } from 'react-native';
import { APP_URI } from './constants';

// Determine the base URL based on the environment
const baseURL =
  Platform.OS === 'web' ? APP_URI : APP_URI?.replace('http://', 'https://');

// Default config options
const defaultOptions: AxiosRequestConfig = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxBodyLength: 100000000,
  maxContentLength: 100000000,
};

// Create axios instance
const axiosClient = axios.create(defaultOptions);

// Helper function to refresh token
const refreshToken = async () => {
  if (auth.currentUser) {
    return auth.currentUser
      .getIdToken(true)
      .then((newToken) => {
        storageHelper.setString('token', newToken);
        return newToken;
      })
      .catch((error) => {
        console.error('Token refresh failed:', error);
        logoutHelper();
        return Promise.reject('Token refresh failed');
      });
  }
  return Promise.reject('No current user for token refresh');
};

// Request interceptor for setting authorization header
axiosClient.interceptors.request.use(async (config) => {
  const token = storageHelper.getString('token');
  if (token && config) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Response interceptor to handle unauthorized error
axiosClient.interceptors.response.use(undefined, async (error) => {
  const originalRequest = error.config;

  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true; // mark the request to prevent infinite loops

    try {
      const newToken = await refreshToken();
      axiosClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${newToken}`; // Update the default token
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return axiosClient(originalRequest); // retry the request with the new token
    } catch (refreshError) {
      console.error('Refresh token error:', refreshError);
      return Promise.reject(refreshError);
    }
  }

  return Promise.reject(error);
});

export default axiosClient;
