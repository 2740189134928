import { Toast, useToastState } from '@tamagui/toast';
import { YStack } from 'tamagui';

export const NativeToast = () => {
  const currentToast = useToastState();

  if (!currentToast || currentToast.isHandledNatively) {
    return null;
  }

  const type = currentToast.customData?.type;

  return (
    <Toast
      key={currentToast.id}
      duration={currentToast.duration || 3000}
      viewportName={currentToast.viewportName}
      enterStyle={{ scale: 0.5, y: -25, o: 0 }}
      exitStyle={{ scale: 1, y: -20, o: 0 }}
      y={0}
      scale={1}
      o={1}
      animation="quick"
    >
      <YStack
        py="$1.5"
        bg={type === 'error' ? '$red100' : '$emerald100'}
        boc={type === 'error' ? '$red800' : '$emerald800'}
        px="$2"
        bw="$1"
        ai="center"
        br="$3"
      >
        <Toast.Title lh="$1">{currentToast.title}</Toast.Title>
        {!!currentToast.message && (
          <Toast.Description fontSize="$5">
            {currentToast.message}
          </Toast.Description>
        )}
      </YStack>
    </Toast>
  );
};
